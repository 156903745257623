<script setup lang="ts">
import { Link, usePage } from '@inertiajs/vue3'
import { useI18n } from 'vue-i18n'
import ProductsSlider from './ProductsSlider.vue'
import { onMounted } from 'vue'
import { useTracking } from '@/Composables/tracking'
import { Product } from '@interfaces/product.interface'

const props = defineProps<{
    products: Array<Product>,
}>()

const page = usePage()
const { t } = useI18n()
const { trackProductsListView } = useTracking()

const jewelleryCategory = page.props.layout.categories.find((category) => category.code === 'jewellery')

onMounted(() => {
    trackProductsListView(props.products, {
        id: 'new-arrivals',
        name: t('pages.home.new-arrivals.title'),
    })
})
</script>

<template>
    <ProductsSlider class="py-16 sm:pb-24" :products="products">
        <template #title>
            {{ t('pages.home.new-arrivals.title') }}
        </template>

        <template #title-link>
            <Link v-if="jewelleryCategory" :href="route('shop.category', jewelleryCategory.slug_path)" class="hidden text-sm font-medium text-indigo-600 hover:text-indigo-500 md:block">
            {{ t('pages.home.new-arrivals.all-products') }}
            <span aria-hidden="true"> &rarr;</span>
            </Link>
        </template>

        <template #footer>
            <div v-if="jewelleryCategory" class="mt-8 text-sm md:hidden">
                <Link :href="route('shop.category', jewelleryCategory.slug_path)" class="font-medium text-indigo-600 hover:text-indigo-500">
                {{ t('pages.home.new-arrivals.all-products') }}
                <span aria-hidden="true"> &rarr;</span>
                </Link>
            </div>
        </template>
    </ProductsSlider>
</template>
